import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import colors from '../../constants/theme';

export const BurgerMenuContainer = styled.div`
  ${MEDIA.MIN_DESKTOP`
      display: none;
 `};
  /* Individual item */
  .bm-item {
    /* Our sidebar item styling */
    text-decoration: none;
    margin-bottom: 15px;
    color: #222;
    transition: color 0.2s;
    font-size: 1.5em;
    :focus {
      outline: none;
    }
    ::-moz-focus-inner {
      border: 0;
    }
  }

  /* Change color on hover */
  .bm-item:hover {
    color: ${colors.color1};
  }

  /* The rest copied directly from react-burger-menu docs */

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: absolute;
    width: 36px;
    height: 30px;
    left: 25px;
    top: 25px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #fff;
    padding: 2.5em 2em 0;
    font-size: 1.15em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    /* color: #b8b7ad; */
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;
