import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.nav`
  margin-left: auto;
  /* font-family: 'Merriweather', sans-serif; */
  /* text-transform: uppercase; */
  font-size: 1em;
  ${MEDIA.DESKTOP`
      display: none;
 `};
  ul {
    display: flex;
    list-style: none;
    padding: 0;
    li {
      & + li {
        margin-left: 2rem;
      }
    }
  }
`;
