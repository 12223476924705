import React from 'react';
import PropTypes from 'prop-types';
import { StyledImg, Wrapper } from './logo.css';

const Logo = ({ image }) => (
  <Wrapper>
    <StyledImg fixed={image.childImageSharp.fixed} />
  </Wrapper>
);

Logo.propTypes = {
  image: PropTypes.object.isRequired,
};

export default Logo;
