import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './pageContainer.css';

const PageContainer = ({ children }) => <Container>{children}</Container>;

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageContainer;
