import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Text = styled.h2`
  font-weight: 600;
  font-size: 2.5em;
  text-align: center;
  line-height: 1;
  ${MEDIA.DESKTOP`
      font-size: 1.5em;
  `};
`;

const Underline = styled.div`
  height: 5px;
  width: 100px;
  border-radius: 10px;
  margin-top: 2em;
  background: ${({ color }) => color};
  ${MEDIA.DESKTOP`
      height: 3px;
      margin-top: 1em;
  `};
`;

const SectionHeader = ({ children }) => (
  <Wrapper>
    <Text>{children}</Text>
    <Underline color={colors.color1} />
  </Wrapper>
);

SectionHeader.propTypes = {
  children: PropTypes.string.isRequired,
};

export default SectionHeader;
