import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
// import posed from 'react-pose';
import { Container } from './header.css';
import Title from 'components/title';
import Nav from 'components/header/nav';
import Logo from 'components/logo';

// Example of a component-specific page transition
// const AnimatedContainer = posed(Container)({
//   enter: {
//     y: 0,
//     transition: {
//       ease: 'easeInOut',
//     },
//   },
//   exit: {
//     y: '-100%',
//     transition: {
//       ease: 'easeInOut',
//     },
//   },
// });

const Header = ({ title, logo }) => (
  <Container>
    <Link to="/" aria-label="Home" className="nav-logo">
      <Logo image={logo} />
    </Link>
    <Link to="/" aria-label="Home">
      <Title className="nav-title" as="h1">
        {title}
      </Title>
    </Link>
    <Nav />
  </Container>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  logo: PropTypes.object.isRequired,
};

export default Header;
