import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .header {
    width: 100%;
    height: calc(100vh - 6em);
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    ${MEDIA.DESKTOP`
      height: calc(100vh - 12em);
    `}
    &:before {
      background-position: center;
    }
    &:after {
      background-position: center;
    }
  }
  .services {
    width: 100%;
    height: 12em;
    display: flex;
    justify-content: center;
    align-items: center;
    ${MEDIA.DESKTOP`
      height: 8em;
    `};
    h1 {
      margin-bottom: 0;
    }
    &:before {
      background-position: center;
    }
    &:after {
      background-position: center;
    }
  }
`;
