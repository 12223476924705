export const accent = '#000';
const palette = 'palette3';

const colors = {
  palette1: {
    color1: '#FAB95B',
    color2: '#71a0a5',
    color3: '#665c84',
    color4: '#212121',
  },
  palette2: {
    color1: '#beeef7',
    color2: '#6fc2d0',
    color3: '#373a6d',
    color4: '#ff8246',
    color5: '#ff8246',
  },
  palette3: {
    color1: '#BF4E30',
    color2: '#EEF0F2',
    color3: '#C6C7C4',
    color4: '#548687',
    color5: '#71816D',
    greyText: '#979797',
    blackText: '#222',
  },
};

export default colors[palette];
