import styled from 'styled-components';
import Img from 'gatsby-image';
// import colors from 'constants/theme';

export const Wrapper = styled.div`
  /* width: 145px; */
  /* height: 145px; */
  /* background: #fff; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  /* margin-top: -60px; */
  z-index: 2;
`;

export const StyledImg = styled(Img)`
  /* width: 200px; */
  /* margin: 2.5em 0 2em 0; */
`;
