import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'gatsby';
import { BurgerMenuContainer } from './burgerMenu.css';

const BurgerMenu = () => (
  <BurgerMenuContainer>
    <Menu pageWrapId={'page-wrap'} width={220}>
      <Link to="/" aria-label="Home">
        Home
      </Link>
      <Link to="/services">Services</Link>
      <Link to="/gallery">Gallery</Link>

      <Link to="/about">About</Link>

      <Link to="/contact">Contact</Link>
    </Menu>
  </BurgerMenuContainer>
);

export default BurgerMenu;
