import styled from 'styled-components';
import Img from 'gatsby-image';
import MEDIA from 'helpers/mediaTemplates';
import { Link } from 'gatsby';
// import colors from '../../constants/theme';

export const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 18em;
  background: rgba(51, 51, 51);
  margin-top: 2em;
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  ${MEDIA.DESKTOP`
    justify-content: space-around;
  `};
  width: 100%;
  width: 960px;
`;

export const ContactTitle = styled.h2`
  font-weight: 500;
  color: #fff;
  margin: 2em 0 1em;
`;

export const Text = styled.p`
  margin-bottom: 0;
  font-size: 0.8em;
  a {
    color: #fff;
    text-decoration: none;
  }
  /* span {
    margin-right: 1em;
  } */
  /* &:last-of-type {
    margin-left: 4em;
  } */
`;

export const ContactInfo = styled.div`
  color: white;
  display: flex;
  align-items: center;
  margin: 2em 0;
  ${MEDIA.DESKTOP`
    margin: 1em 0;
    flex-direction: column;
  `}
  ${Text} {
    margin: 1em 2em;
    ${MEDIA.DESKTOP`
      margin: 0.5em 0;
    `}
  }
`;

export const Logo = styled(Img)`
  ${MEDIA.DESKTOP`
      display: none !important; 
  `};
`;

export const SocialMediaContainer = styled.div`
  display: flex;
  align-items: center;
  a {
    margin: 0 1.5rem;
  }
  ${MEDIA.DESKTOP`
    margin-top: 0.5em;
  `};
`;

export const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  color: #fff;
  ${Text} {
    font-weight: 600;
    font-size: 1.2em;
  }
  ${MEDIA.DESKTOP`
    ${Text} {
      font-size: 1em;
      line-height: 2;
    }
  `};
`;

export const StyledLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  margin: 0 2em;
  ${MEDIA.DESKTOP`
      margin: 0 0.8em;
  `};
  &:hover {
    text-decoration: underline;
  }
`;
