import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.header`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 2em;
  width: 100%;
  height: 6em;
  ${MEDIA.DESKTOP`
    height: 5em;
  `};
  a {
    color: #757575;
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }
  .nav-title {
    margin-left: 1em;
    font-size: 1em;
    /* font-family: 'Merriweather', sans-serif; */
    ${MEDIA.DESKTOP`
      display: none;
    `};
  }
  .nav-logo {
    ${MEDIA.DESKTOP`
      display: none;
    `};
  }
`;
