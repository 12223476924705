import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  max-width: 1230px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 4em;
  ${MEDIA.DESKTOP`
    width: 100%;
    padding: 0;
 `};
`;
