import React from 'react';
import PropTypes from 'prop-types';
import FbIcon from '../../../content/images/fb-icon.svg';
import InstaIcon from '../../../content/images/instagram-icon.svg';
import TwitterIcon from '../../../content/images/twitter-icon.svg';

import {
  FooterContainer,
  ContentContainer,
  ContactInfo,
  Text,
  SocialMediaContainer,
  LinkContainer,
  StyledLink,
} from './footer.css';

const Footer = () => (
  <FooterContainer>
    <ContentContainer>
      <LinkContainer>
        <StyledLink to="/">
          <Text>Home</Text>
        </StyledLink>
        <StyledLink to="/services">
          <Text>Services</Text>
        </StyledLink>
        <StyledLink to="/gallery">
          <Text>Gallery</Text>
        </StyledLink>
        <StyledLink to="/about">
          <Text>About</Text>
        </StyledLink>
        <StyledLink to="/contact">
          <Text>Contact</Text>
        </StyledLink>
      </LinkContainer>
      <ContactInfo>
        <Text>
          <span>Phone:</span> +44 7929885287
        </Text>
        <Text>
          <span>Email:</span>{' '}
          <a href="mailto:ckeelty@hotmail.co.uk">ckeelty@hotmail.co.uk</a>
        </Text>
      </ContactInfo>
      <SocialMediaContainer>
        <a
          href="https://www.facebook.com/Christopher-Keelty-Master-Thatcher-344108629741342/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Facebook"
        >
          <FbIcon />
        </a>
        <a
          href="https://www.instagram.com/chris.keelty/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Instagram"
        >
          <InstaIcon />
        </a>
        <a
          href="https://twitter.com/thatch_it"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Twitter"
        >
          <TwitterIcon />
        </a>
      </SocialMediaContainer>
    </ContentContainer>
  </FooterContainer>
);

Footer.propTypes = {
  logo: PropTypes.object.isRequired,
};

export default Footer;
